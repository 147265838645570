import { VERTICAL_SCROLL_OFFSET } from '@core/config-angular';

export const DEPLOY_PATH_VALUE = 'dist/energiedirect/browser';
export const DEPLOY_PATH_PROVIDER = {
  provide: 'DEPLOY_PATH',
  useValue: DEPLOY_PATH_VALUE,
};

export const VERTICAL_SCROLL_OFFSET_PROVIDER = {
  provide: VERTICAL_SCROLL_OFFSET,
  useValue: 80,
};
