/* eslint-disable no-restricted-globals */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { initSentry } from '@innogy/core/observability';
import { bootstrapCurrentClientHydration } from '@essent/current-ssr-middleware/dist/client';
import { getClientEnvironmentConfig } from '@core/config-angular';

import { DEPLOY_PATH_VALUE } from './app/app.constants';
import { environment } from './environments/environment';

import 'hammerjs';

if (environment.production) {
  enableProdMode();
}

getClientEnvironmentConfig(DEPLOY_PATH_VALUE)
  .then(initSentry)
  .catch(console.error);

document.readyState !== 'loading'
  ? bootstrap()
  : document.addEventListener('DOMContentLoaded', bootstrap);

async function bootstrap() {
  try {
    const config = await getClientEnvironmentConfig(DEPLOY_PATH_VALUE);
    if (config?.designSystem.enabledOnSsr) {
      // Hydrate CRNT before importing the Angular app to prevent duplicate rendering.
      await bootstrapCurrentClientHydration();
    }
    const { EnergieDirectAppModule } = await import('./app/app.module');
    await platformBrowserDynamic().bootstrapModule(EnergieDirectAppModule);
  } catch (error) {
    console.error(error);
  }
}
