import type { ClientEnvironmentConfig } from '@core/config-models';

/**
 * The id of the script element that contains the environment config.
 */
export const APP_STATE_ELEMENT_ID = 'innogy-app-state';

function getConfigFromAppState() {
  let envConfig: ClientEnvironmentConfig | undefined = undefined;
  // eslint-disable-next-line no-restricted-globals
  const appStateElement = document.getElementById(APP_STATE_ELEMENT_ID);

  if (!appStateElement) {
    return undefined;
  }

  try {
    envConfig = JSON.parse(appStateElement.innerHTML)
      .config as ClientEnvironmentConfig;
  } catch (error) {
    console.error(`Error parsing environment.json config: ${error}`);
  }

  return envConfig;
}

async function getConfigFromEnvironmentJson(deployUrl: string) {
  let config: ClientEnvironmentConfig | undefined = undefined;
  try {
    const response = await fetch(`${deployUrl}/environment.json`);
    config = await response.json();
  } catch (error) {
    console.error(`Error fetching environment.json config: ${error}`);
  }
  return config;
}

export async function getClientEnvironmentConfig(
  deployUrl: string
): Promise<ClientEnvironmentConfig | undefined> {
  // First get the config from the SSR-transferred application state if it exists.
  let config = getConfigFromAppState();

  // Test if the config is loaded properly by trying to access a key that should always be defined (e.g. sentry)
  if (!config?.sentry) {
    // If not, load it from the environment.json file
    config = await getConfigFromEnvironmentJson(deployUrl);
  }

  // If the config or sentry key is still not found, log an error.
  if (!config?.sentry) {
    console.error(`Error fetching the client environmentConfig`);
  }

  return config;
}
